import { Controller } from "@hotwired/stimulus"
import { marked } from "marked"

export default class VendorPolicyController extends Controller {

    static targets = ['modalId', "modalTitle", "modalContent"]; // Declare targets for modal title and content

    openModal(event) {
        event.preventDefault();

        const { modalId, modalTitle, modalBody, modalLink } = event.currentTarget.dataset;

        const modalContent = document.getElementById(modalId);
        const modal = FlowbiteInstances.getInstance('Modal', modalId);


        const titleEl = modalContent.querySelector("#vendor_policy_modal-title");
        const bodyEl = modalContent.querySelector("#vendor_policy_modal-body");
        const buttonEl = modalContent.querySelector("#vendor_policy_modal-button");

        titleEl.innerHTML = modalTitle;
        bodyEl.innerHTML = marked.parse(modalBody);
        if (modalLink) {
            buttonEl.href = modalLink;
            buttonEl.classList.remove('hidden');
            buttonEl.classList.add('flex');
        } else {
            buttonEl.classList.add('hidden');
            buttonEl.classList.remove('flex');
        }

        modal.show();
    }
}