import {Controller} from "@hotwired/stimulus";

export default class EstimatedMonthlyPaymentController extends Controller {


    connect() {
        super.connect();

        try {
            const { price, target } = this.element.dataset;

            if (!price) {
                console.warn('Unable to display estimated monthly payment. Price is not set');
                return;
            }
            if (!target) {
                console.warn('Unable to display estimated monthly payment. Target is not set');
                return;
            }
            if (!stripe) {
                console.warn('Unable to display estimated monthly payment. Stripe is not available.');
                return;
            }

            const elements = stripe.elements({
                appearance: {
                    variables: {
                        fontSizeBase: '11px',
                        fontLineHeight: '13px'
                    }
                }
            });
            const options = {
                amount: parseInt(price),
                currency: 'USD',
                countryCode: 'US',
                debug: true
            };

            this.PaymentMessageElement = elements.create('paymentMethodMessaging', options);
            this.PaymentMessageElement.mount(`#${target}`);
        } catch (error) {
            console.error('EstimatedMonthlyPaymentController.failed', error);
        }
    }

}