import { Controller } from "@hotwired/stimulus"

export default class ModalController extends Controller {

    _modal = null;
    _syntheticModal = false;
    _initAttempts = 0;

    get autoLaunch() {
        return this.element.dataset.autoLaunch?.toLowerCase() === 'true'
    }

    async connect() {
        this.modal = await this.initializeModal();
        this.modal.updateOnHide(() => {
            if (this._syntheticModal) {
                FlowbiteInstances.destroyAndRemoveInstance('Modal', this.id);
                this.modal = null;
            }
        });

        if (this.autoLaunch) {
            this.show();
        }
    }

    get id() {
        return this.element.getAttribute('id');
    }

    async initializeModal() {
        if (this.modal) { return this.modal; }

        if (FlowbiteInstances.instanceExists('Modal', this.id)) {
            this._syntheticModal = false;
            return FlowbiteInstances.getInstance('Modal', this.id);
        }

        if (this.autoLaunch || this._initAttempts > 20) {
            return this.createModal();
        }

        await this.wait(10);
        this._initAttempts += 1;
        return await this.initializeModal();
    }

    async wait(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    createModal() {
        this._syntheticModal = true;
        return new Modal(this.element);
    }

    disconnect() {
        this.modal?.hide();
    }

    show() {
        this.modal?.show();
    }

    hide() {
        this.modal?.hide();
    }

}